<template>

	<div v-show="loading">
		<tw-loading />
	</div>

	<!-- Search Bar -->
	<div v-show="!loading" class="mb-17">
		<div 
			class="w-11/12 md:w-8/12 lg:w-10/12 fixed bg-gray-50 py-6 top-17 h-24">					
			<tw-input 
				v-model="searchInput"
				id="search"
				placeholder="Search bookings..."
				@input="searchFunction(searchInput)"
				padding-leading-inline="12">
				<template v-slot:leading_inline>
					<tw-button
						class="border-none bg-transparent hover:bg-transparent">
						<MagnifyingGlassIcon class="h-5" />
					</tw-button>
				</template>
				<template v-slot:trailing_inline>
					<tw-button
						v-if="searchInput.length"
						@click="clearSearchBar" 
						class="border-none bg-transparent hover:bg-transparent">
						<XMarkIcon class="h-5" />
					</tw-button>
				</template>
			</tw-input>

		</div>	
	</div>

	<!-- Bookings -->
	<div v-show="!loading">
		<div v-if="bookings.length > 0">

			<!-- Large screen table view -->
			<div v-if="windowSize === 'lg'">
				<tw-table :data="filteredBookings">
					<tw-table-column
						prop="_GMT#"
						header="GMT Ref"
						:sortable="true"
						:sort-method="( ) => { sortMethod('_GMT#', sortDirection) }" />
					<tw-table-column
						prop="SSLineCompany"
						header="SS Line Name"
						:sortable="true"
						:sort-method="( ) => { sortMethod('SSLineCompany', sortDirection) }" />
					<tw-table-column
						prop="_Booking#"
						header="Booking No."
						:sortable="true"
						:sort-method="( ) => { sortMethod('_Booking#', sortDirection) }" />
					<tw-table-column
						prop="_shipperReference#"
						header="Shipper Ref No."
						:sortable="true"
						:sort-method="( ) => { sortMethod('_shipperReference#', sortDirection) }" />
					<tw-table-column
						prop="zctListContainersSemiColon"
						header="Container No."
						:sortable="true"
						:sort-method="( ) => { sortMethod('zctListContainersSemiColon', sortDirection) }" />
					<tw-table-column
						prop="placeOfReceiptCity"
						header="Place of Receipt"
						:sortable="true"
						:sort-method="( ) => { sortMethod('placeOfReceiptCity', sortDirection) }" />
					<tw-table-column
						prop="portOfLoadingCity"
						header="Loading Port"
						:sortable="true"
						:sort-method="( ) => { sortMethod('portOfLoadingCity', sortDirection) }" />
					<tw-table-column
						prop="portOfDischargeCity"
						header="Discharge Port"
						:sortable="true"
						:sort-method="( ) => { sortMethod('portOfDischargeCity', sortDirection) }" />
					<tw-table-column
						prop="placeOfDeliveryCity"
						header="Place of Delivery"
						:sortable="true"
						:sort-method="( ) => { sortMethod('placeOfDeliveryCity', sortDirection) }" />
					<tw-table-column
						prop="ETDDatePort"
						header="Sailing Date"
						:sortable="true"
						:sort-method="( ) => { sortMethod('ETDDatePort', sortDirection) }" />
					<tw-table-column
						prop="ETADatePort"
						header="Arrival Date"
						:sortable="true"
						:sort-method="( ) => { sortMethod('ETADatePort', sortDirection) }" />
					<tw-table-column
						prop="zctWarningHoldListSingleLine"
						header="Hold Status"
						class-name="text-red-500"
						:sortable="true"
						:sort-method="( ) => { sortMethod('zctWarningHoldListSingleLine', sortDirection) }" />
					<!-- <tw-table-column v-slot="{row}">
						<tw-button 
							v-show="row.fieldData.zcnIsShipperHold"
							@click="showConfirmationModal(row.fieldData)"
							class="btn-green uppercase w-38 h-10 whitespace-normal text-xs">
							Request remove shipper hold
						</tw-button>
					</tw-table-column> -->
				</tw-table>
			</div>

			<!-- Sm/Md screen card view -->
			<div
				v-else
				v-for="(booking, index) in filteredBookings"
				:key="index">
				<Booking :booking="booking.fieldData" :load-data-function="loadPage" />
			</div>
		</div>

		<div v-else class="text-2xl font-bold">
			No bookings to display.
		</div>
	</div>

</template>

<script lang="ts">
	// name the component
	export default { name: 'OnHoldReport' }
</script>

<script setup lang="ts">

	// API
	import sharedApi from '@/api/SharedApi'

	// Component
	import Booking from '../Booking.vue'

	// Store
	import { userStore } from '@/store/index'

	// Vue
	import { 
		ref, 
		onMounted,
		computed
	} from 'vue'

	const store = userStore()

	const bookings = ref([])
	const filteredBookings = ref([])
	const hasError = ref(false)
	const errorMessage = ref('')
	const reportType = ref(store.user.bookingReportType)

	const windowWidth = ref(window.innerWidth)

	function onWidthChange() {
		windowWidth.value = window.innerWidth
	}

	const windowSize = computed(() => {
		if (windowWidth.value > 1575) return 'lg'
		else return 'sm/md'
	})

	onMounted(() => {
		window.addEventListener('resize', onWidthChange)
		loadPage()
	})

	onMounted(() => {
		loadPage()
	})

	const loading = ref(true)

	async function loadPage(){

		let params

		if(reportType.value === 'agent'){
			// Agent Report

			// Get current date minus 180 days
			let todaysDate = new Date()
			todaysDate.setDate(todaysDate.getDate() - 180)
			// Format date mm/dd/yyyy
			let dateReport = new Date(todaysDate).toLocaleDateString()
			
			params = { 
						query: [{
							agentCompany: store.user.companyName,
							ETADatePort: `>${dateReport}`,
							bookingCanceled: "=",
							bookingRolled: "=",
							zcnIsAnyHold: "1",
							zcnIsPrePaidOrCollect: "="
						}] 
					}
		}
		else if(reportType.value === 'shipper'){
			// Shipper Report
			params = { 
						query: [{
							_kfnShipperCompanyID: store.user.kftCompanyID,
							ETADatePort: "//...",
							bookingCanceled: "=",
							bookingRolled: "=",
							zcnIsAnyHold: "1"
						}] 
					}
		}
		else{
			// Direct Customer Report
			params = { 
						query: [{
							reportReferenceCustomer: store.user.reportReferenceCustomer,
							ETADatePort: "//...",
							bookingCanceled: "=",
							bookingRolled: "=",
							zcnIsAnyHold: "1"
						}] 
					}
		}

		try{
			let result = await sharedApi.findBookings(params)			

			if(result.messages[0].code != 0){
				let error = result.messages[0].message
				throw error
			}

			bookings.value = result.response.data
			// Sort by ETADatePort ASC
			filteredBookings.value = result.response.data.sort((a, b) => a.fieldData.ETADatePort > b.fieldData.ETADatePort ? 1 : -1 )
			loading.value = false
		} 
		catch(error: any){
			loading.value = false
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
		}

	}

	//#region Search

	const searchInput = ref('')

	/**
	 * Filter the list of bookings on GMT #, Booking #, & Shipper Reference #
	 * @function searchFunction
	 * @param {string} criteria - The search criteria
	*/
	async function searchFunction(criteria: string){
		loading.value = true

		// await loadPage()

		criteria = criteria.toLowerCase()
		
		// Filter on fields and sort by ETADatePort ASC
		let bookingsFiltered = bookings.value.filter((booking) => {
			return booking.fieldData['_GMT#'].toLowerCase().includes(criteria) || 
				booking.fieldData['SSLineCompany'].toLowerCase().includes(criteria) || 
				booking.fieldData['_Booking#'].toLowerCase().includes(criteria) || 
				booking.fieldData['_shipperReference#'].toLowerCase().includes(criteria) ||
				booking.fieldData['zctListContainersSemiColon'].toLowerCase().includes(criteria) || 
				booking.fieldData.ETDDatePort.includes(criteria) ||
				booking.fieldData.ETADatePort.includes(criteria) ||
				booking.fieldData.placeOfReceiptCity.toLowerCase().includes(criteria) ||
				booking.fieldData.portOfLoadingCity.toLowerCase().includes(criteria) ||
				booking.fieldData.portOfDischargeCity.toLowerCase().includes(criteria) ||
				booking.fieldData.placeOfDeliveryCity.toLowerCase().includes(criteria) || 
				booking.fieldData.zctWarningHoldListSingleLine.toLowerCase().includes(criteria)
		}).sort((a, b) => a.fieldData.ETADatePort > b.fieldData.ETADatePort ? 1 : -1 )

		filteredBookings.value = bookingsFiltered

		document?.getElementById('search')?.focus()
		
		loading.value = false
	}

	/**
	 * Clear the search filter and reload bookings
	 * @function clearSearchBar
	*/
	async function clearSearchBar(){
		searchInput.value = ''
		filteredBookings.value = bookings.value
		// await loadPage()
	}

	//#endregion

	//#region Sort

	const sortDirection = ref('ASC')

	function sortMethod(field: string, sortDir: string){
		if(sortDir === 'ASC'){
			sortDirection.value = 'DESC'
			filteredBookings.value = filteredBookings.value.sort((a, b) => a.fieldData[`${field}`] < b.fieldData[`${field}`] ? 1 : -1 )
		} 
		else {
			sortDirection.value = 'ASC'
			filteredBookings.value = filteredBookings.value.sort((a, b) => a.fieldData[`${field}`] > b.fieldData[`${field}`] ? 1 : -1 )
		}
	}

	//#endregion


</script>