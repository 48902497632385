<template>
	<div v-show="!loading" class="mb-3">
		<dt class="text-sm font-bold text-gray-500 font-gotham uppercase">
			{{ props.label }}
		</dt>
		<dd :class="valueClass" class="mt-1 text-md font-bold text-gray-900">
			{{ props.value || props.placeholder }}
		</dd>
	</div>
	<div v-show="loading">
		<div class="h-4 rounded-full bg-gray-300 animate-pulse w-1/2" />
		<div class="mt-3 h-6 rounded-full bg-gray-300 animate-pulse w-full" />
	</div>
</template>

<script setup lang="ts">

	const props = defineProps({ 
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: '—'
		},
		value: {
			type: [ String, Number ],
			default: null
		},
		valueClass: {
			type: String,
			default: ''
		},
		badge: {
			type: Boolean,
			default: false
		}
	})


</script>
