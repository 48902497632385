<template>
	<div class="flex min-h-screen flex-1">
		<!-- Background image -->
		<div class="hidden w-0 flex-1 lg:block min-h-screen relative overflow-hidden">
			<img
				class="absolute inset-0 h-full min-h-260 w-full object-cover"
				src="@/assets/ucbg.jpeg"
				alt="">
		</div>

		<!-- Login Form -->
		<div class="min-h-screen min-w-full lg:min-w-0">
			<div class="min-h-screen">
				<div class="py-3 px-6 flex flex-1 flex-col justify-start lg:flex-none bg-white min-h-[90.6vh]">
					<div class="w-full px-4 sm:px-6 h-full">
						<div>
							<img
								class="h-52 w-auto mx-auto"
								src="@/assets/logo-vertical.png"
								alt="Global Marine Transportation Logo">
							<h2 class="text-center mt-8 text-lg lg:text-2xl font-bold leading-9 tracking-tight text-gray-900">
								Update Password
							</h2>
						</div>
  
						<div class="mt-10 max-w-sm lg:w-96 mx-auto">
							<div>
								<form
									@submit.prevent="updatePassword"
									class="space-y-6">
									<div>
										<tw-input
											id="password"
											label="New Password"
											placeholder="Please enter a new password"
											type="password"
											required
											v-model="password">
											<template #trailing_inline>
												<tw-button
													@click="toggleShowPassword" 
													class="border-none bg-transparent hover:bg-transparent">
													<EyeIcon
														v-if="!showPassord"
														class="h-5" />
													<EyeSlashIcon
														v-else
														class="h-5" />
												</tw-button>
											</template>
										</tw-input>
									</div>
  
									<div>
										<tw-input
											id="confirm-password"
											label="Confirm New Password"
											placeholder="Please confirm your new password"
											type="password"
											required
											v-model="confirmPassword">
											<template #trailing_inline>
												<tw-button
													@click="toggleShowPassword" 
													class="border-none bg-transparent hover:bg-transparent">
													<EyeIcon
														v-if="!showPassord"
														class="h-5" />
													<EyeSlashIcon
														v-else
														class="h-5" />
												</tw-button>
											</template>
										</tw-input>
									</div>

									<!-- Hidden text -->
									<div 
										v-show="hasError" 
										class="text-red-500 mt-3">
										{{ errorMessage }}
									</div>

									<!-- Hidden text -->
									<div 
										v-show="hasPasswordReqError" 
										class="text-red-500 mt-3">
										<p>Password must meet the following requirements:</p>
										<div class="flex justify-center mt-1">
											<ul class="list-disc">
												<li>Minimum Length: {{ passwordMinLength }}</li>
												<li>Maximum Length: {{ passwordMaxLength }}</li>
												<li v-if="passwordRequireCapLetter">Must include atleast one capital letter</li>
												<li v-if="passwordRequireNumerical">Must include atleast one number</li>
												<li v-if="passwordRequireSpecialCharacters">Must include atleast one special character</li>
											</ul>
										</div>
									</div>

									<!-- Loading icon -->							
									<div 
										v-if="loading"
										class="flex justify-center items-center">
										<tw-loading />
										<p class="text-orange-500 ml-3">Updating password...</p>
									</div>	
  
									<div
										v-if="!loading">
										<button
											type="submit"
											class="btn-green flex w-full justify-center py-2">
											Update
										</button>
									</div>

									<div class="flex items-center justify-between">
										<div class="text-sm leading-6 mx-auto">
											<router-link 
												:to="{name: 'LoginMain'}" 
												class="link">
												Back To Login
											</router-link>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				
				<tw-footer />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'UpdatePassword' }
</script>

<script setup lang="ts">

	// API
	import sharedApi from '@/api/SharedApi'

	// Router
    import {
		useRouter,
		useRoute
	} from 'vue-router'

	// Store
	import { userStore } from '@/store/index'

	// Vue
    import { 
		ref, 
		onMounted
	} from 'vue'


    const store = userStore()
	const router = useRouter()
    const route = useRoute()

    const password = ref('')
	const confirmPassword = ref('')
	const hasError = ref(false)
	const errorMessage = ref('')
	const loading = ref(false)

	const passwordMinLength = ref(0)
	const passwordMaxLength = ref(0)
	const passwordRequireCapLetter = ref(0)
	const passwordRequireNumerical = ref(0)
	const passwordRequireSpecialCharacters = ref(0)

	onMounted(async () => {
		try {

			// Get preferences
			let res = await sharedApi.getPreferences()

			// Ensure no errors
			let errCode = res.messages[0].code
			if(errCode != 0){
				let err = res.messages[0].message
				throw err
			}

			let preferencesRecord = res.response.data[0].fieldData

			passwordMinLength.value = preferencesRecord.passwordMinLength
			passwordMaxLength.value = preferencesRecord.passwordMaxLength
			passwordRequireCapLetter.value = preferencesRecord.passwordIncludeCapLetter
			passwordRequireNumerical.value = preferencesRecord.passwordIncludeNumerical
			passwordRequireSpecialCharacters.value = preferencesRecord.passwordIncludeSpecialCharacters

		}
		catch(e) {
			console.log('Error:', e)
		}
	})

	const hasPasswordReqError = ref(false)

	async function updatePassword(){
		loading.value = true

		// Confirm passwords match
		if(password.value !== confirmPassword.value){
			hasError.value = true
			errorMessage.value = 'Passwords do not match.'
			loading.value = false
			return
		}

		if(!isPasswordValid()){
			hasPasswordReqError.value = true
			loading.value = false
			return
		}

		let params = {
				contactID: store.user.kptID,
				password: password.value
            }

		try{
			let result = await sharedApi.runScript("Contact - Change Password", JSON.stringify(params))

			if (JSON.parse(result.response.scriptResult).errorCode != 0){
                let error = JSON.parse(result.response.scriptResult).errorMessage
				throw error		
            } else {
				// Navigate back to dashboard
				router.push(route?.query?.redirect ? String(route?.query?.redirect) : { name: 'Dashboard' })
			}
			
		} catch(error: any){
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
			loading.value = false
		}
	}

	const showPassord = ref(false)

	function toggleShowPassword(){
		const passwordField = document.querySelector('#password')
		const confirmPasswordField = document.querySelector('#confirm-password')

		if(passwordField?.getAttribute('type') === 'password'){
			passwordField.setAttribute('type', 'text')
			confirmPasswordField?.setAttribute('type', 'text')
			showPassord.value = true
		}
		else{
			passwordField?.setAttribute('type', 'password')
			confirmPasswordField?.setAttribute('type', 'password')
			showPassord.value = false
		}
	}

	function isPasswordValid(){

		let hasCapLetter = /[A-Z]/.test(password.value)
		let hasNumber = /[0-9]/.test(password.value)
		let hasSpecialChar = /[^A-Za-z0-9]/.test(password.value)

		// Password length
		if(password.value.length < passwordMinLength.value || password.value.length > passwordMaxLength.value) return false
		
		// Cap Letter, Number, and Special Character
		if(passwordRequireCapLetter.value && passwordRequireNumerical.value && passwordRequireSpecialCharacters.value && (!hasCapLetter || !hasNumber || !hasSpecialChar)) return false
		// Cap Letter and Number
		else if(passwordRequireCapLetter.value && passwordRequireNumerical.value && (!hasCapLetter || !hasNumber)) return false
		// Cap Letter and Special Character
		else if(passwordRequireCapLetter.value && passwordRequireSpecialCharacters.value && (!hasCapLetter || !hasSpecialChar)) return false
		// Number and Special Character
		else if(passwordRequireNumerical.value && passwordRequireSpecialCharacters.value && (!hasNumber || !hasSpecialChar)) return false
		// Cap Letter
		else if(passwordRequireCapLetter.value && !hasCapLetter) return false
		// Number
		else if(passwordRequireNumerical.value && !hasNumber ) return false
		// Special Character
		else if(passwordRequireSpecialCharacters.value && !hasSpecialChar) return false

		return true
	}

</script>