<template>
	<div class="uppercase text-xl font-bold">My Profile</div>
	<div class="mt-3 grid grid-cols-3">
		<data-display 
			class="col-span-3 md:col-span-1"
			label="First Name" 
			:value="store.user.firstName" />
		<data-display 
			class="col-span-3 md:col-span-1"
			label="Last Name" 
			:value="store.user.lastName" />
		<data-display 
			class="col-span-3 md:col-span-1"
			label="Email" 
			:value="store.user.email" />
		<data-display 
			class="col-span-3 md:col-span-1"
			label="Company" 
			:value="store.user.companyName" />
		<data-display 
			class="col-span-3 md:col-span-1"
			label="Location" 
			:value="store.user.locationName" />
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'AccountDetails' }
</script>

<script setup lang="ts">
    
    // Store
    import { userStore } from '@/store/index'

    const store = userStore()

</script>
