<template>
	<!-- Notification -->
	<tw-notification 
		v-if="showNotification" 
		@close="showNotification = false" 
		:title="notificationTitle" 
		:subtitle="notificationSubtitle" 
		:duration="8000" 
		:icon="notificationIcon" />

	<!-- Sm/Md screen card view -->
	<div v-else class="card mb-6">
		<div class="grid grid-cols-4 text-sm">
			<div class="col-span-4 flex lg:col-span-1">
				<p>GMT #:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking['_GMT#'] }}
				</p>
			</div>

			<div class="col-span-4 flex lg:col-span-1">
				<p>SS Line Booking #:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking['_Booking#'] }}
				</p>
			</div>

			<div class="col-span-4 flex lg:col-span-1">
				<p>Shipper Ref:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking['_shipperReference#'] }}
				</p>
			</div>

			<div 
				v-if="props.booking.zctWarningHoldList && type !== 'sm'"
				class="col-span-4 flex lg:col-span-1">
				<p>Hold Status:</p>
				<p class="ml-3 float-right uppercase font-bold text-red-500">
					{{ props.booking.zctWarningHoldList }}
				</p>
			</div>

			<div 
				v-else-if="type !== 'sm'"
				class="col-span-4 flex lg:col-span-1">
				<p class="ml-3 float-right uppercase font-bold text-red-500" />
			</div>

			<div
				v-if="type !== 'sm'" 
				class="col-span-4 flex lg:col-span-1">
				<p>ETD:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.ETDDatePort }}
				</p>
			</div>
			
			<div 
				v-if="type !== 'sm'" 
				class="col-span-4 flex lg:col-span-1">
				<p>ETA:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.ETADatePort }}
				</p>
			</div>
		</div>

		<!-- Display for sm screens -->
		<div 
			v-if="type === 'sm'"
			class="grid grid-cols-4 text-sm">
	
			<div class="col-span-4 flex">
				<p>Place of Receipt:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.placeOfReceiptCity }}
				</p>
			</div>

			<div class="col-span-4 flex">
				<p>Port of Loading:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.portOfLoadingCity }}
				</p>
			</div>

			<div class="col-span-4 flex">
				<p>Port of Discharge:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.portOfDischargeCity }}
				</p>
			</div>

			<div class="col-span-4 flex">
				<p>Place of Delivery:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.placeOfDeliveryCity }}
				</p>
			</div>

			<div class="col-span-4 flex">
				<p>ETD:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.ETDDatePort }}
				</p>
			</div>
			
			<div class="col-span-4 flex">
				<p>ETA:</p>
				<p class="ml-3 float-right uppercase">
					{{ props.booking.ETADatePort }}
				</p>
			</div>

			<div 
				v-if="props.booking.zctWarningHoldList"
				class="col-span-4 flex">
				<p>Hold Status:</p>
				<p class="ml-3 float-right uppercase font-bold text-red-500">
					{{ props.booking.zctWarningHoldListSingleLine }}
				</p>
			</div>

			<div 
				v-else
				class="col-span-4 flex">
				<p class="ml-3 float-right uppercase font-bold text-red-500" />
			</div>

			<div class="col-span-4 flex flex-1 mr-3 ml-auto mt-3">
				<!-- <tw-button 
					class="btn-brand uppercase mr-3">
					View Details
				</tw-button> -->
				<tw-button 
					v-show="props.booking.zcnHasSSLRelease && routeName === 'StatusReport'"
					@click="downloadSslRelease"
					class="btn-orange uppercase mr-3 ml-auto">
					Download SSL Release
				</tw-button>
				<tw-button 
					v-show="props.booking.zcnIsShipperHold && routeName === 'StatusReport'"
					@click="() => confirmationModalOpen = true"
					class="btn-green uppercase mr-3 ml-auto">
					Request remove shipper hold
				</tw-button>
			</div>

		</div>

		<!-- Icon display for md+ screens -->
		<div
			v-else 
			class="grid grid-cols-2 mt-3 text-xs">
			<div class="col-span-2 lg:col-span-1 flex flex-1">
				<CityIcon
					size="30px"
					color="#46a3ff" />
				<div class="block ml-1 mr-6">
					<p>Place of Receipt:</p>
					<p class="uppercase">
						{{ props.booking.placeOfReceiptCity }}
					</p>
				</div>

				<ShipIcon
					size="30px"
					color="#46a3ff" />
				<div class="block ml-1 mr-6">
					<p>Port of Loading:</p>
					<p class="uppercase">
						{{ props.booking.portOfLoadingCity }}
					</p>
				</div>

				<ShipIcon
					size="30px"
					color="#46a3ff" />
				<div class="block ml-1 mr-6">
					<p>Port of Discharge:</p>
					<p class="uppercase">
						{{ props.booking.portOfDischargeCity }}
					</p>
				</div>

				<CityIcon
					size="30px"
					color="#46a3ff" />
				<div class="block ml-1 mr-6">
					<p>Place of Delivery:</p>
					<p class="uppercase">
						{{ props.booking.placeOfDeliveryCity }}
					</p>
				</div>
			</div>

			<div class="col-span-2 lg:col-span-1 flex flex-1 mr-3 ml-auto mt-3">
				<!-- <tw-button 
					class="btn-brand uppercase mr-3">
					View Details
				</tw-button> -->
				<tw-button 
					v-show="props.booking.zcnHasSSLRelease && routeName === 'StatusReport'"
					@click="downloadSslRelease"
					class="btn-orange uppercase mr-3 ml-auto">
					Download SSL Release
				</tw-button>
				<tw-button 
					v-show="props.booking.zcnIsShipperHold && routeName === 'StatusReport'"
					@click="() => confirmationModalOpen = true"
					class="btn-green uppercase mr-3 ml-auto">
					Request remove shipper hold
				</tw-button>
			</div>
		</div>
	</div>

	<!-- Confirmation Modal -->
	<ConfirmationModal
		v-if="confirmationModalOpen"
		:show="confirmationModalOpen"
		:booking="props.booking"
		:load-data-function="props.loadDataFunction"
		@close-modal="(value:boolean) => confirmationModalOpen = value"
		@script-success="(value:boolean) => showNotificationModal(value)" />
</template>

<script lang="ts">
	// name the component
	export default { name: 'BookingCard' }
</script>

<script setup lang="ts">

	// API
	import sharedApi from '@/api/SharedApi'

	// Components
	import ConfirmationModal from './components/ConfirmationModal.vue'

	//Router
	import { useRoute } from 'vue-router'

	// Store
	import { userStore } from '@/store/index'

	// SVG
	import CityIcon from '../svg/CityIcon.vue'
	import ShipIcon from '../svg/ShipIcon.vue'

   // Vue
   import {
		ref,
		computed,
		onMounted, 
		onUnmounted
   } from 'vue'

    const props = defineProps({ 
		booking: {
            type: Object
        },
		loadDataFunction: {
			type: Function,
			default: () => {}
		}
	})

	const store = userStore()
	const route = useRoute()

	const confirmationModalOpen = ref(false)

	const windowWidth = ref(window.innerWidth)

	function onWidthChange() {
		windowWidth.value = window.innerWidth
	}

	const routeName = ref('')

	onMounted(() => {
		window.addEventListener('resize', onWidthChange)
		routeName.value = route.name
	})

	onUnmounted(() => {
		window.removeEventListener('resize', onWidthChange)
	})

	const type = computed(() => {
		if (windowWidth.value < 550) return 'sm'
		if (windowWidth.value >= 550 && windowWidth.value < 1200) return 'md'
		else return 'lg'
	})

	const notificationTitle = ref('')
	const notificationIcon = ref(0)
	const showNotification = ref(false)
	const notificationSubtitle = ref('')

	function showNotificationModal(scriptValue:boolean){
		if(scriptValue){
			notificationIcon.value = 0
			notificationTitle.value = 'Success'
			notificationSubtitle.value = 'Removed shipper hold'
		}
		else{
			notificationIcon.value = 1
			notificationTitle.value = 'Error'
			notificationSubtitle.value = 'Could not remove shipper hold'
		}

		showNotification.value = true
	}

	async function downloadSslRelease(){
		let params = {
				bookingID: props.booking['_GMT#'],
				email: store.user.email
            }
		
		try {
            let result = await sharedApi.runScript("Booking - Download SSL Release", JSON.stringify(params))

			if (JSON.parse(result.response.scriptResult).errorCode != 0){
                let error = JSON.parse(result.response.scriptResult).errorMessage
				throw error
            }

			const pdfBase64 = JSON.parse(result.response.scriptResult).document
			const fileName = JSON.parse(result.response.scriptResult).fileName

			const linkSource = `data:application/pdf;base64,${pdfBase64}`
			const downloadLink = document.createElement("a")
			
			downloadLink.href = linkSource
			downloadLink.download = fileName
			downloadLink.click()

			notificationIcon.value = 0
			notificationTitle.value = 'Success'
			notificationSubtitle.value = 'Downloaded SSL Release'
			showNotification.value = true

        } catch (error: any) {
            console.log('Error:', error)
			notificationIcon.value = 1
			notificationTitle.value = 'Error'
			notificationSubtitle.value = 'Could not download SSL Release'
			showNotification.value = true
        }
	}

</script>