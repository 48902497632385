<template>
	<div class="brand-color w-full flex justify-start gap-3 lg:gap-15 align-middle items-center">
		<div class="bg-white m-3">
			<img
				class="h-20"
				src="@/assets/logo-horizontal.png"
				alt="Global Marine Transportation Logo" />
		</div>
	</div>

	<div class="grid grid-cols-2 gap-12 flex-1 flex-col justify-center px-6 py-12 lg:px-56 min-h-[79.5vh]">
		<div class="col-span-2 font-bold text-4xl mx-auto">
			Thank you for completing sign up {{ store.user.firstName }} {{ store.user.lastName }}!
		</div>
		
		<div class="col-span-2 text-xl mx-auto border border-orange-400 p-6 h-40 md:h-28 text-red-500">
			<p>Please check your email for a temporary password.</p>
			<p>You will be required to change password after first login.</p>
		</div>

		<div class="col-span-2 mx-auto mt-20">
			<tw-button 
				@click="() => router.push({name: 'LoginFormSignup'})"
				class="btn-green w-96 justify-center">
				Continue
			</tw-button>
		</div>

	</div>
	
	<tw-footer class="-ml-4 sm:-ml-6" />
</template>

<script lang="ts">
	// name the component
	export default { name: 'SignupVerification' }
</script>

<script setup lang="ts">

	// Router
	import {
		useRouter
	} from 'vue-router'

	// Store
	import { userStore } from '@/store/index'
	
	const router = useRouter()
	const store = userStore()

</script>