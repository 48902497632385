<template>
	<div class="brand-color w-full flex justify-start gap-3 lg:gap-15 align-middle items-center">
		<div class="bg-white m-3">
			<img
				class="h-20"
				src="@/assets/logo-horizontal.png"
				alt="Global Marine Transportation Logo" />
		</div>
	</div>

	<div class="grid grid-cols-2 gap-12 min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-56">
		<div class="col-span-2 font-bold text-4xl">
			Sign Up
		</div>
		<div class="col-span-2 sm:col-span-1">
			<tw-input
				disabled
				label="First Name"
				v-model="store.user.firstName" />
		</div>
		<div class="col-span-2 sm:col-span-1">
			<tw-input
				disabled
				label="Last Name"
				v-model="store.user.lastName" />
		</div>
		<div class="col-span-2">
			<tw-input
				disabled
				label="Email"
				v-model="store.user.email"
				type="email" />
		</div>
		<div class="col-span-2">
			<tw-input
				disabled
				label="Company"
				v-model="store.user.companyName" />
		</div>

		<div class="col-span-2">
			<tw-input
				disabled
				label="Location"
				v-model="store.user.locationName" />
		</div>

		<!-- Hidden text -->
		<div 
			v-show="hasError" 
			class="text-red-500 mt-3 col-span-2">
			{{ errorMessage }}
		</div>

		<!-- Loading icon -->							
		<div 
			v-if="loading"
			class="flex justify-center items-center col-span-2">
			<tw-loading />
			<p class="text-orange-500 ml-3">Confirming signup...</p>
		</div>	

		<div class="col-span-2">
			<tw-button 
				v-if="!loading"
				@click="signUp"
				class="btn-green w-full justify-center">
				Confirm
			</tw-button>
		</div>

		<div class="col-span-2 mx-auto">
			<router-link 
				:to="{name: 'LoginMain'}" 
				class="link">
				Back To Login
			</router-link>
		</div>
	</div>
	<tw-footer footer-position="relative" />
</template>

<script lang="ts">
	// name the component
	export default { name: 'SignUp' }
</script>

<script setup lang="ts">

	// API
	import sharedApi from '@/api/SharedApi'

	// Router
	import {
		useRouter,
		useRoute
	} from 'vue-router'

	// Store
	import { userStore } from '@/store/index'

	// Vue
    import {
		ref
    } from 'vue'


	
	const router = useRouter()
	const route = useRoute()
	const store = userStore()

    const hasError = ref(false)
	const errorMessage = ref('')
	const loading = ref(false)

	async function signUp(){
		loading.value = true

		let params = {
				contactID: store.user.kptID
            }

		try{
			let result = await sharedApi.acceptPortalInvite(JSON.stringify(params))

			let scriptResult = JSON.parse(result.response.scriptResult)

			if(scriptResult.errorCode != 0){
				let error = scriptResult.errorMessage
				throw error
			}
			else{
				router.push({name: 'SignupVerification'})
			}
		} 
		catch(error: any){
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
			loading.value = false
		}

	}

</script>