<template>
	<footer
		:class="props.footerPosition"
		class="brand-color flex flex-1 flex-col justify-center px-12 py-6 sm:px-6 lg:flex-none lg:px-20 min-w-full">
		<a
			href="#" 
			class="link-white text-sm mx-auto">Global Marine Transportation Inc. {{ year }} &trade;</a>
		<span class="text-white text-sm mx-auto">1 (212) 717-7754</span>
	</footer> 
</template>

<script setup lang="ts">

    // #region IMPORT STATEMENTS

    import { computed } from 'vue'

    // #region

    // #region COMPUTES CURRENT YEAR

    const year = computed(() => {
        return new Date().getFullYear()
    })

    // #endregion

    // absolute or relative

    const props = defineProps({
        footerPosition: {
            type: String
        }
    })

</script>
