<template>
	<div>
		<Listbox
			as="div"
			v-model="_modelValue"
			:multiple="multiple"
			@update:model-value="value => onSelect(value)">
			<ListboxLabel class="block text-sm font-medium text-gray-700">
				{{ label }}
			</ListboxLabel>
			<div class="mt-1 relative">
				<ListboxButton
					id="listBoxButton" 
					class="relative w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-brand-500 focus:outline-none focus:ring-1 focus:ring-brand-500 sm:text-sm"
					:class="{'bg-gray-200 cursor-not-allowed pointer-events-none': disabled, 'bg-white cursor-default': !disabled}">
					<span
						v-if="!filterText"
						class="block truncate"
						:class="{'text-gray-600': !modelLabel}">{{ modelLabel || _placeholder }}</span>
					<span
						v-else
						class="truncate flex align-middle">{{ filterText }}
						<span
							class="h-4 bg-black inline-block"
							style="content: ''; width: 1px; animation: cursor-blink 1s steps(2) infinite;" />
					</span>
					<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
						<XCircleIcon 
							class="h-5 w-5 text-brand-500 hover:text-brand-700 pointer-events-auto" 
							aria-hidden="true" 
							v-show="!disabled && modelLabel"
							v-if="clearable"
							@click.stop="clear" />
						<ChevronUpDownIcon
							class="h-5 w-5 text-gray-400"
							aria-hidden="true"
							v-show="!disabled" />
					</span>
				</ListboxButton>

				<transition
					leave-active-class="transition ease-in duration-100"
					leave-from-class="opacity-100"
					leave-to-class="opacity-0">
					<ListboxOptions
						@focus="addListener"
						@click="removeListener"
						class="absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
						<ListboxOption
							as="template"
							:key="option.value" 
							v-for="option in filteredOptions" 
							v-slot="{ active, selected }"
							:value="option.value">
							<li
								:class="[active ? 'text-white bg-brand-500' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
								<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
									{{ option.label }}
								</span>
								<span
									v-if="selected"
									:class="[active ? 'text-white' : 'text-brand-500', 'absolute inset-y-0 right-0 flex items-center pr-4']">
									<CheckIcon
										class="h-5 w-5"
										aria-hidden="true" />
								</span>
							</li>
						</ListboxOption>
						<ListboxOption
							as="template"
							v-show="noFilteredOptions">
							<li class="pointer-events-none select-none relative py-2 pl-3 pr-9">
								<span>No results</span>
							</li>
						</ListboxOption>
					</ListboxOptions>
				</transition>
			</div>
		</Listbox>
	</div>
</template>

<script setup lang="ts">
    // #region imports
    import {
		ref,
		computed,
		nextTick 
	} from 'vue'
    import {
		Listbox,
		ListboxButton,
		ListboxLabel,
		ListboxOption,
		ListboxOptions
	} from '@headlessui/vue'
    import {
		CheckIcon,
		ChevronUpDownIcon
	} 
	from '@heroicons/vue/24/solid'
    // #endregion


    // #region setup
	const emit = defineEmits([
        'update:modelValue'
    ]);

    const props = defineProps({
		modelValue: {
            type: [String, Number, Array],
            default: null
        },
		clearable: {
            type: Boolean,
            default: false
        },
		disabled: {
            type: Boolean,
            default: false
        },
		filterable: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
		multiple: {
			type: Boolean,
			default: false
		},
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'None selected'
        },
		searchable: {
            type: Boolean,
            default: false
        },
		searchMethod: {
            type: Function,
            default: () => {return}
        },
       
		
    })
    // #endregion


    // #region editing model value
    const _modelValue = computed(() => {
        return props.modelValue
    })

	/**
     * Text to display in the select box. Formats multiple selections as a comma separated string.
     * @yields {string}
     */
	const modelLabel = computed(() =>{
		if(Array.isArray(props.modelValue)){
			let selectedOptions: any = []
			props.modelValue.forEach(value =>{
				const selectedOption: any = props.options.find((option: any) => {
					return option.value === value
				})
				selectedOptions.push(selectedOption.label)
			})
			return selectedOptions.join(', ')
		}
		else{
			const selectedOption: any = props.options.find((option: any) => {
				return option.value === props.modelValue
			})
			return selectedOption?.label || null
		}
	})

    const _placeholder = ref(props.placeholder)
    // #region editing model value

    /**
     * Updates the bound value of the select menu
     * @function onSelect
     * @param {Object} option - the selected option of the select menu
     */
    async function onSelect(value: any) {
        emit('update:modelValue', value)
        await nextTick()
        return
    }

     /**
     * Clears the selected value
     * @function clear
     */
    async function clear(){
        emit('update:modelValue', {})
        filterText.value = ''
        await nextTick()
        return
    }
    // #endregion

    // #region filtering options
    /**
     * Adds event listener for filter when select menu is focused
     * @function addFilterListener
     */
    function addListener() {
        if(props.disabled) return
		else if(props.filterable) _placeholder.value = 'Type to filter list...'
		else if(props.searchable) _placeholder.value = 'Type to search...'
        filterText.value = ''
        document.addEventListener('keydown', keyPress, true)
		// document.addEventListener('click', test, true)
    }

    /**
     * Removes event listener for filter when select menu is unfocused
     * @function removeListener
     */
    function removeListener() {
        _placeholder.value = props.placeholder
        document.removeEventListener('keydown', keyPress, true)
		// document.removeEventListener('click', test, true)
        filterText.value = ''
    }


    const filterText = ref('')

     /**
     * Event to capture keystrokes to filter select options
     * @function keyPress
     */
    function keyPress(event: any) {
		const key = event.key
        if (key === "Backspace") {
            filterText.value = filterText.value.slice(0, -1)
			if(props.searchable && filterText.value) {
				props.searchMethod(filterText.value)
			}
        }
        else if(key === "Escape") {
            removeListener()
        }
        else if((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90) || (key == ' ')) {
            filterText.value = filterText.value + key
			if(props.searchable && filterText.value) {
				props.searchMethod(filterText.value)
			}
        }

		
    }

    /**
     * List of options filtered by filter text
     * @yields {array}
     */
    const filteredOptions = computed(() => {
		if(props.searchable && !filterText.value) return []
		return props.options.filter((option: any) => {
			return !props.filterable || !filterText.value || option.label.toLowerCase().includes(filterText.value.toLowerCase()) == true
		})
    })

    /**
     * If no options remain when filtered by filter text
     * @yields {boolean}
     */
    const noFilteredOptions = computed(() => {
        return filteredOptions.value.length === 0
    })
    // #endregion

</script>
