<template>
	<component
		v-if="isLoaded"
		:is="layout">
		<router-view />
	</component>
</template>

<script setup lang="ts">

	//#region Imports

	// API
	import LoginAPI from './modules/login/api/LoginApi'

	// Vue
    import {
		computed,
		onMounted,
		ref
	} from 'vue';

	// Router
    import {
		useRoute
	} from 'vue-router'

	// Store
	import { userStore } from '@/store/index'
	
    import MainLayout from './layouts/DefaultLayout.vue'
    // import BareLayout from './layouts/BareLayout.vue'

	//#endregion

	const isLoaded = true

	const route = useRoute()
	const store = userStore()

	const layout = computed(() => {
		return route.meta.layout || MainLayout
	})

	onMounted(() => {
		document.addEventListener("keypress", resetTimer, false);
		document.addEventListener("mousemove", resetTimer, false);
		document.addEventListener("mousedown", resetTimer, false);
		document.addEventListener("touchmove", resetTimer, false);
		
		startTimer();
	})

	// Timeout time is in ms => {{x}} minutes x 60 x 1000
	const timeOutTime = ref(600000)
	const timeoutID = ref(null)

	/**
	 * To start the inactivity timer
	 * @function startTimer
	*/
	function startTimer() { 
		// setTimeout returns an ID (can be used to start or clear a timer)
		timeoutID.value = setTimeout(handleInactive, timeOutTime.value)
	}

	/**
	 * To log the user out after inactivity
	 * @function handleInactive
	*/
	function handleInactive() {
		if(store.user.loggedIn){
			// console.log('LOGGING OUT FROM INACTIVITY...')
			LoginAPI.logout()
		}
	}

	/**
	 * To reset the inactivity timer
	 * @function resetTimer
	*/
	function resetTimer() { 
		clearTimeout(timeoutID.value)
		startTimer()
	}

</script>