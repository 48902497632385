import { ListBulletIcon } from '@heroicons/vue/24/outline'
import Booking from './BookingMain.vue'

const config = {
	mainRoute: {
		name: "StatusReport",
		path: "/status-report",
		component: Booking
	},
	nav: {
		name: "Status Report",
		to: "StatusReport",
		icon: ListBulletIcon,
		position: 1,
		show: true
	},
	module: "booking"
}

export { config }