<template>
	<div>
		<div class="mt-4 flex items-center justify-between">
			<div>
				<h1 class="text-xl font-semibold text-gray-900">
					{{ title }}
				</h1>
				<p 
					v-if="description" 
					class="mt-2 text-sm text-gray-700">
					{{ description }}
				</p>			
			</div>
			<TransitionRoot
				v-if="filterable"
				as="template"
				:show="filterOpen">
				<Dialog
					as="div"
					class="relative z-40 sm:hidden"
					@close="filterOpen = false">
					<TransitionChild
						as="template"
						enter="transition-opacity ease-linear duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leave-from="opacity-100"
						leave-to="opacity-0">
						<div class="fixed inset-0 bg-black bg-opacity-25" />
					</TransitionChild>

					<div class="fixed inset-0 z-40 flex">
						<TransitionChild
							as="template"
							enter="transition ease-in-out duration-300 transform"
							enter-from="translate-x-full"
							enter-to="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leave-from="translate-x-0"
							leave-to="translate-x-full">
							<DialogPanel class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
								<div class="flex items-center justify-between px-4">
									<h2 class="text-lg font-medium text-gray-900">
										Filters
										<span 
											:class="{'tabular-nums text-sm': selectedFilters.length>0 }">
											{{ selectedFilters.length>0 ? "(" + selectedFilters.length + ")" : "" }} </span>
									</h2>
									
									<button
										type="button"
										class="-mr-2 flex h-10 w-10 items-center justify-center rounded-none bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500"
										@click="filterOpen = false">
										<span class="sr-only">Close menu</span>
										<XMarkIcon
											class="h-6 w-6"
											aria-hidden="true" />
									</button>
								</div>
								<!-- Filters -->
								<form class="mt-4">
									<Disclosure
										as="div"
										v-for="(section, i) in filters"
										:key="i"
										class="border-t border-gray-200 px-4 py-6"
										v-slot="{ filterOpen }">
										<h3 class="-mx-2 -my-3 flow-root">
											<DisclosureButton class="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
												<span>
													<span class="font-medium text-gray-900">{{ section.label }}</span>
													<span
														v-show="showNumOptionsSelected(section)" 
														class="ml-1.5 rounded-none bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
														{{ numOptionsSelected(section) }}
													</span>	
												</span>
												<span class="ml-6 flex items-center">
													<ChevronDownIconMini
														:class="[filterOpen ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform']"
														aria-hidden="true" />
												</span>
											</DisclosureButton>
										</h3>
										<DisclosurePanel class="pt-6">
											<div class="space-y-6">
												<div
													v-for="(option, optionIdx) in section.options"
													:key="option.value"
													class="flex items-center">
													<input
														:id="`filter-mobile-${section.label}-${optionIdx}`"
														:name="`${section.label}`"
														:value="option.value"
														@change="doFiltering($event, section.function, option.label)"
														v-model="option.selected"
														type="checkbox"
														class="h-4 w-4 rounded-none border-gray-300 text-brand-600 focus:ring-brand-500">
													<label
														:for="`filter-mobile-${section.label}-${optionIdx}`"
														class="ml-3 text-sm text-gray-500">{{ option.label }}</label>
												</div>
											</div>
										</DisclosurePanel>
									</Disclosure>
								</form>
							</DialogPanel>
						</TransitionChild>
					</div>
				</Dialog>
			</TransitionRoot>
			<section
				v-if="filterable"
				aria-labelledby="filter-heading">
				<h2
					id="filter-heading"
					class="sr-only">
					Filters
				</h2>

				<div class="flex items-center justify-between">					
					<button
						type="button"
						class="flex items-center group text-sm font-medium text-gray-700 sm:hidden"
						@click="filterOpen = true">
						<FunnelIconMini 
							class="inline h-5 w-5 mr-1 text-gray-400 group-hover:text-gray-500" 
							aria-hidden="true" />
						{{ filtersApplied }}
					</button>
					
					<PopoverGroup class="hidden sm:flex sm:items-baseline sm:space-x-8">
						<div class="flext items-center justify-center text-sm font-medium text-gray-700">
							<FunnelIconMini 
								class="inline h-5 w-5 mr-1 text-gray-400" 
								aria-hidden="true" />
							{{ filtersApplied }}
						</div>
						<Popover
							as="div"
							v-for="(section, sectionIdx) in filters"
							:key="section.label"
							:id="`desktop-menu-${sectionIdx}`"
							class="relative inline-block text-left">							
							<div>
								<PopoverButton class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
									<span>{{ section.label }}</span>
									<span
										v-show="showNumOptionsSelected(section)" 
										class="ml-1.5 rounded-none bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
										{{ numOptionsSelected(section) }}
									</span>				
									<ChevronDownIconMini
										class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true" />
								</PopoverButton>
							</div>

							<transition
								enter-active-class="transition ease-out duration-100"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95">
								<PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-none bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
									<form class="space-y-4">
										<div
											v-for="(option, optionIdx) in section.options"
											:key="option.value"
											class="flex items-center">
											<input
												:id="`filter-${section.label}-${optionIdx}`"
												:name="`${section.label}[]`"
												:value="option.value"
												@change="doFiltering($event, section.function, option.label)"
												v-model="option.selected"
												type="checkbox"
												class="h-4 w-4 rounded-none border-gray-300 text-brand-600 focus:ring-brand-500">
											<label
												:for="`filter-${section.label}-${optionIdx}`"
												class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">{{ option.label }}</label>
										</div>
									</form>
								</PopoverPanel>
							</transition>
						</Popover>
					</PopoverGroup>
				</div>
			</section>
		</div>
		<div 
			v-if="searchable" 
			class="w-full">					
			<div class="relative mt-3 flex items-center">
				<div class="absolute items-center left-0 pl-1.5">
					<MagnifyingGlassIcon 
						class="h-5 w-5 text-gray-400" 
						aria-hidden="true" />
				</div>
				<label 
					for="search" 
					class="sr-only">
					Search
				</label>
				<input 
					type="text" 
					name="search" 
					id="search"
					:placeholder="searchPlaceholder"
					@change="searchMethod($event.target.value)" 
					class="block rounded-none border-gray-300 pl-8 pr-12 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm w-full">					
			</div>
		</div>	
		<div class="mt-4 flex flex-col">
			<div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
					<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-none">
						<table class="min-w-full divide-y divide-gray-300 table-fixed">
							<thead class="bg-gray-50">
								<tr>
									<th 	
										v-if="checkable" 
										scope="col" 
										class="relative items-center sm:w-16 sm:px-3">
										<input 
											type="checkbox" 
											class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded-none border-gray-300 text-brand-500 focus:ring-brand-500 sm:left-6"
											:checked="indeterminate || selectedRows.length === data.length"
											:indeterminate="indeterminate"
											@change="selectedRows=$event.target.checked ? data.map((r,i) => i) : []">
										<tw-table-dropdown
											v-if="selectedRows.length > 0"
											class="py-3 -mr-12 ml-4 pr-4" 
											:items="multiRowActions" 
											position="left"
											:data="data"
											:selected-table-rows="selectedRows">
											<template #icon>
												<ChevronDownIconMini />
											</template>										
										</tw-table-dropdown>																												
									</th>
									<tw-table-header :default-sort="defaultSort">
										<slot />
									</tw-table-header>
								</tr>
							</thead>
							<tbody 
								v-if="data.length>0"
								class="divide-y divide-gray-200 bg-white">								
								<tr
									v-for="(row,i) in data" 
									:key="row">
									<td 
										v-if="checkable" 
										class="relative w-12 px-6 sm:w-16 sm:px-8">
										<div 
											v-if="selectedRows.includes(i)" 
											class="absolute inset-y-0 left-0 w-0.5 bg-brand-500" />
										<input 
											type="checkbox"
											class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded-none border-gray-300 text-brand-500 focus:ring-brand-500 sm:left-6"
											:value="i"
											v-model="selectedRows">
									</td>
									<tw-table-row :row="row">
										<slot />
									</tw-table-row>
								</tr>
								<tr v-show="loading">
									<td 
										class="text-center w-full p-4" 
										colspan="100%">
										<tw-loading />
									</td>
								</tr>
							</tbody>
							<tbody 
								v-else
								class="divide-y divide-gray-200 bg-white">	
								<tr>									
									<td 
										colspan="100%" 
										class="text-center whitespace-nowrap px-3 py-4 text-sm text-gray-500">
										{{ placeholder }}
									</td>
								</tr>
								<tr v-show="loading">
									<td 
										class="text-center w-full p-4" 
										colspan="100%">
										<tw-loading />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'TwTable' }
</script>


<script setup lang="ts">
	import {
		defineProps,
		ref,
		computed
	} from 'vue'
	
	import {
		Dialog,
		DialogPanel,
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		Popover,
		PopoverButton,
		PopoverGroup,
		PopoverPanel,
		TransitionChild,
		TransitionRoot
	} from '@headlessui/vue'

	const props = defineProps({
		checkable: {
			type: Boolean,
			default: false
		},
		data: {
			type: Array,
			default: () => {return []}
		},
		defaultSort: {
			type: Object,
			default: () => {return {prop: null, order: 'ASC'}}
		},
		description: {
			type: String,
			default: ""
		},
		filterable:{
			type: Boolean,
			default: false
		},
		filters:{
			type: Array[{label: String, options: Array, method: Function}],
			default: () => {return []}
		},
		loading: {
			type: Boolean,
			default: false
		},
		multiRowActions: {
			type: Array, 
			default: () => {return []}
		},
		placeholder: {
			type: String,
			default: "No Data"
		},
		rowActions: {
			type: Array,
			default: () => {return []}
		},
		searchable:{
			type: Boolean,
			default: false
		},
		searchMethod:{
			type: Function,
			default: () => {return}
		},
		searchPlaceholder: {
			type: String,
			default: "Search..."
		},
		title: {
			type: String,
			default: ""
		}
	})

	const filterOpen = ref(true)
	const selectedRows = ref([])
	const selectedFilters = ref([])
	// eslint-disable-next-line
	const checked = ref(false)
	const indeterminate = computed(()=> selectedRows.value.length > 0 && selectedRows.value.length < props.data.length)

	let filtersArr: any = []

	/**
	 * The number of options selected for an individual filter
	 * @function numOptionsSelected
	 * @param {Object} section - The filter object
	 */
	function numOptionsSelected(section: any){
		return section.options.filter((o: any) =>{return o.selected}).length
	}

	/**
	 * Determines if the number of options selected should be shown
	 * @function showNumOptionsSelected
	 * @param {Object} section - The filter object
	 */
	function showNumOptionsSelected(section: any){
		return numOptionsSelected(section) > 0
	}

	const filtersApplied = ref('No filters applied')
	/**
	 * Generates text to indicate how many filters are applied
	 * @function updateFiltersApplied
	 */
	function updateFiltersApplied() {
		let numFiltersApplied = props.filters.filter(s => {
			return s.options.some(o => {
				return o.selected && o.selected != false && o.selected != null && o.selected != undefined
			})
		}).length

		filtersApplied.value = `${numFiltersApplied || 'No'} Filter${numFiltersApplied !== 1 ? 's' : ''} Applied`
	}
	/**
	 * Generates text to indicate how many filters are applied
	 * @function doFiltering
	 * @param {Event} e - The filter selection
	 * @param {Function} filteringFunction - The function to call to filter
	 * @param {Object} filterParam - The filter query for that filter selection 
	 */
	function doFiltering(e: any, filteringFunction: any, filterParam: any){
		if(e.target.checked){
			filtersArr.push(filterParam)
		}else{
			filtersArr.splice(filtersArr.indexOf(filterParam), 1)
		}
		updateFiltersApplied()
		filteringFunction(filtersArr)
	}
</script>