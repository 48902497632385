<template>
	<div>
		<p class="text-2xl font-bold">
			Hello, {{ store.user.firstName }} {{ store.user.lastName }}
		</p>
		<tw-button
			@click="() => router.push({name: 'StatusReport'})"
			class="uppercase btn-orange mt-3 w-48 justify-center">
			Status Report
		</tw-button>
		<tw-button
			@click="() => router.push({name: 'OnHoldReport'})"
			class="uppercase btn-green ml-3 mt-3 w-48 justify-center">
			On Hold Report
		</tw-button>
	</div>

</template>

<script lang="ts">
	// name the component
	export default { name: 'DashboardMain' }
</script>

<script setup lang="ts">

	// Router
	import {
		useRouter
	} from 'vue-router'

	// Store
	import { userStore } from '@/store/index'

	const store = userStore()
	const router = useRouter()

</script>