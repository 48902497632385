import { ListBulletIcon } from '@heroicons/vue/24/outline'
import OnHoldReport from './OnHoldReport.vue'

const config = {	
	mainRoute: {
		name: "OnHoldReport",
		path: "/on-hold-report",
		component: OnHoldReport
	},
	nav: {
		name: "On Hold Report",
		to: "OnHoldReport",
		icon: ListBulletIcon,
		position: 2,
		show: true
	},
	module: "onHoldReport"
}

export { config }