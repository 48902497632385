import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const userStore = defineStore('store', {
	// a function that returns a fresh state
	state: () => ({
        // user: {
        //     kptID: "",
        //     kftCompanyID: "",
        //     email: "",
        //     firstName: "",
        //     lastName: "",
        //     companyName: "",
        //     locationName: "",
        //     sessionId: "",
        //     isPasswordChangeNeeded: "",
        //     loggedIn:false
        // }
        user: useStorage('user', {
            kptID: "",
            kftCompanyID: "",
            email: "",
            firstName: "",
            lastName: "",
            companyName: "",
            locationName: "",
            sessionId: "",
            isPasswordChangeNeeded: "",
            loggedIn:false,
            reportReferenceCustomer: "",
            bookingReportType: ""
        })
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {
        // setUserData(payload: any) {
        //     this.user = payload;
        // }
	}
})