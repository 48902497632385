<template>
	<!-- <LoginForm /> -->
	<!-- <SignUp /> -->
	<!-- <MfaForm /> -->
	<!-- <ResetPassword /> -->
	<!-- <UpdatePassword /> -->
	<LoginForm v-if="currentRoute == 'LoginMain'" />
	<MfaForm v-else-if="currentRoute == 'MfaForm'" />
	<MfaFormSignup v-else-if="currentRoute == 'MfaFormSignup'" />
	<SignUp v-else-if="currentRoute == 'SignUp'" />
	<ResetPassword v-else-if="currentRoute == 'ResetPassword'" />
	<UpdatePassword v-else-if="currentRoute == 'UpdatePassword'" />
	<LoginFormSignup v-else-if="currentRoute == 'LoginFormSignup'" />
	<SignupVerification v-else-if="currentRoute == 'SignupVerification'" />
</template>

<script setup lang="ts">
    /* eslint-disable */

	import LoginForm from './components/LoginForm.vue'
    import MfaForm from './components/MfaForm.vue'
	import MfaFormSignup from './components/MfaFormSignup.vue'
    import ResetPassword from './components/ResetPassword.vue'
    import UpdatePassword from './components/UpdatePassword.vue'
    import SignUp from './components/SignUp.vue'
	import SignupVerification from './components/SignupVerification.vue'
	import LoginFormSignup from './components/LoginFormSignup.vue'


    import {
		computed
	} from 'vue'

    import {
		useRoute
	} from 'vue-router'


    const currentRoute = computed( () => {
        return useRoute().name
    })



</script>

<style scoped>
	.min-h-260 {
		min-height: 65rem;
	}

</style>