/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import { router } from './router/index'
import './assets/css/index.css'

// import global components
import TwBadge from '@/components/Badges/TwBadge.vue'
import TwButton from '@/components/Buttons/TwButton.vue'
import TwCheckbox from '@/components/Checkboxes/TwCheckbox.vue'
import TwInput from '@/components/InputGroups/TwInput.vue'
import TwLoading from '@/components/Loading/TwLoading.vue'
import TwSelect from '@/components/SelectMenus/TwSelect.vue'
import TwTable from '@/components/Tables/TwTable.vue'
import TwTableColumn from '@/components/Tables/TwTableColumn.vue'
import TwTableDropdown from '@/components/Tables/TwTableDropdown.vue'
import TwTableHeader from '@/components/Tables/TwTableHeader.vue'
import TwTableRow from '@/components/Tables/TwTableRow.vue'
import TwFooter from '@/components/Footer/TwFooter.vue'
import DataDisplay from '@/components/DataDisplay/DataDisplay.vue'
import TwNotification from '@/components/Notifications/TwNotification.vue'

// import icons from Tailwind's Hero Icons
// Solid icons
import {
	BarsArrowUpIcon,
	ExclamationCircleIcon,
	HomeIcon,
	MagnifyingGlassIcon,
	PlusSmallIcon,
	BuildingOffice2Icon,
	EyeIcon,
	EyeSlashIcon,
	CheckCircleIcon
} from '@heroicons/vue/24/solid'

// Outline icons
import { 
	XCircleIcon,
	XMarkIcon
} from '@heroicons/vue/24/outline'

// Mini icons
import {
	ChevronDownIcon as ChevronDownIconMini,
	FunnelIcon as FunnelIconMini
} from '@heroicons/vue/20/solid'

const pinia = createPinia()
const app = createApp(App)

// Register icons globally
app.component('BarsArrowUpIcon', BarsArrowUpIcon)
app.component('ChevronDownIconMini', ChevronDownIconMini)
app.component('ExclamationCircleIcon', ExclamationCircleIcon)
app.component('FunnelIconMini', FunnelIconMini)
app.component('HomeIcon', HomeIcon)
app.component('MagnifyingGlassIcon', MagnifyingGlassIcon)
app.component('PlusSmIcon', PlusSmallIcon)
app.component('XCircleIcon', XCircleIcon)
app.component('XMarkIcon', XMarkIcon)
app.component('BuildingOffice2Icon', BuildingOffice2Icon)
app.component('EyeIcon', EyeIcon)
app.component('EyeSlashIcon', EyeSlashIcon)
app.component('CheckCircleIcon', CheckCircleIcon)
// In another component use, <HomeIcon/> directly in the template

// Register global components
app.component("tw-badge", TwBadge)
app.component("tw-button", TwButton)
app.component("tw-checkbox", TwCheckbox)
app.component("tw-input", TwInput)
app.component("tw-loading", TwLoading)
app.component("tw-table", TwTable)
app.component("tw-table-column", TwTableColumn)
app.component("tw-table-dropdown", TwTableDropdown)
app.component("tw-table-header", TwTableHeader)
app.component("tw-table-row", TwTableRow)
app.component("tw-select", TwSelect)
app.component("tw-footer", TwFooter)
app.component("data-display", DataDisplay)
app.component("tw-notification", TwNotification)


//Axios Setup
if( localStorage.sessionId == undefined){
	localStorage.sessionId = Math.random().toString(36).substring(2)
}
let sessionId = localStorage.sessionId

if (window.location.hostname == 'localhost'){
    axios.defaults.baseURL = 'http://localhost:8888/gmt-fm-web/api/v1/'
}else{
	//const gmtBaseURL: string = (process.env.VUE_APP_APIURL as string);
	//setBaseUrl(process.env.APIURL)
    //const gmtBaseURL: string = (process.env.API_BASE_URL as string);
	//axios.defaults.baseURL = gmtBaseURL
	// this env is ignored unless prefixed with VUE_APP_
	axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
	// axios.defaults.baseURL = process.env['API_BASE_URL']
}
axios.defaults.headers.common['Session-Id'] = sessionId ;



app.use(pinia)
app.use(router)
app.mount('#app')