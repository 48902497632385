<template>
	<div class="flex min-h-screen flex-1">
		<!-- Background image -->
		<div class="hidden w-0 flex-1 lg:block min-h-screen relative overflow-hidden">
			<img
				class="absolute inset-0 h-full min-h-260 w-full object-cover"
				src="@/assets/ucbg.jpeg"
				alt="">
		</div>

		<!-- Login Form -->
		<div class="min-h-screen min-w-full lg:min-w-0">
			<div class="min-h-screen">
				<div class="py-3 px-6 flex flex-1 flex-col justify-start lg:flex-none bg-white min-h-[90.6vh]">
					<div class="w-full px-4 sm:px-6 h-full">
						<div>
							<img
								class="h-52 w-auto mx-auto"
								src="@/assets/logo-vertical.png"
								alt="Global Marine Transportation Logo">
							<div class="text-sm mt-8 leading-6 text-center border border-orange-400 p-6 font-bold text-red-500">
								<p>Please check your email for a temporary password.</p>
								<p>You will be required to change password after first login.</p>
							</div>
							<h2 class="text-center mt-8 text-lg lg:text-2xl font-bold leading-9 tracking-tight text-gray-900">
								Initial Login
							</h2>
						</div>
  
						<div class="mt-10 max-w-sm lg:w-96 mx-auto">
							<div>
								<form
									@submit.prevent="login"
									class="space-y-6">
									<div>
										<tw-input
											label="Email"
											placeholder="Email"
											type="email"
											required
											v-model="email" />
									</div>

									<div>
										<tw-input
											id="password"
											label="Temporary Password"
											placeholder="One-Time Password"
											type="password"
											required
											v-model="password">
											<template #trailing_inline>
												<tw-button
													@click="toggleShowPassword" 
													class="border-none bg-transparent hover:bg-transparent">
													<EyeIcon
														v-if="!showPassord"
														class="h-5" />
													<EyeSlashIcon
														v-else
														class="h-5" />
												</tw-button>
											</template>
										</tw-input>
									</div>

									<div 
										v-show="loginError" 
										class="text-red-500 mt-3">
										<p>Login attempt failed. Please try again or contact us for assistance.</p>
									</div>

									<!-- Loading icon -->							
									<div 
										v-if="loginLoading"
										class="flex justify-center items-center">
										<tw-loading />
										<p class="text-orange-500 ml-3">Verifying login...</p>
									</div>	
  
									<div v-if="!loginLoading">
										<button
											type="submit"
											class="btn-green flex w-full justify-center">
											Login
										</button>
									</div>

									<div class="text-sm leading-6 text-center">
										<router-link 
											:to="{name: 'ResetPassword'}" 
											class="link">
											Forgot password?
										</router-link>
									</div>
								</form>
							</div>
						</div>
					</div>	
				</div>

				<tw-footer />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'LoginFormSignup' }
</script>

<script setup lang="ts">

	// API
	import LoginAPI from '../api/LoginApi'

	// Router
    import {
		useRouter
	} from 'vue-router'

	// Vue
    import { 
		ref
	} from 'vue'

	// Store
	import { userStore } from '@/store/index'

    const router = useRouter()
    const store = userStore()

    const email = ref("")
    const password = ref("")
	const loginLoading = ref(false)
	const loginError = ref(false)


    async function login() {
		loginLoading.value = true
		
		// check for empty field values
		if (email.value == "") {
			document.getElementById('email')?.focus()
			loginError.value = true
			loginLoading.value = false
			return
		}
		else if (password.value == "") {
			document.getElementById('password')?.focus()
			loginError.value = true
			loginLoading.value = false
			return
		}

		// attempt login
		LoginAPI.login(email.value, password.value, (response: any ) => {
			if (!response.authenticated) {
				loginError.value = true
				loginLoading.value = false
				return
			}

			// update store
			Object.assign(store.user, response.user)

			router.push({name:'MfaForm'})
		})
    }

	const showPassord = ref(false)

	function toggleShowPassword(){
		const passwordField = document.querySelector('#password')

		if(passwordField?.getAttribute('type') === 'password'){
			passwordField.setAttribute('type', 'text')
			showPassord.value = true
		}
		else{
			passwordField.setAttribute('type', 'password')
			showPassord.value = false
		}
	}

</script>