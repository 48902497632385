import axios from 'axios';


export default {
    runScript: function(script: string, parameters: string) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : script,
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },

    acceptPortalInvite: function(parameters: string) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : 'Contact - Accept Portal Invite',
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },

    createUserRequest: function(parameters: string) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : 'User Request - Create Request',
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },

    changePassword: function(email: string, oldPassword: string, newPassword: string) {
        const data = {
            email: email,
            oldPassword: oldPassword,
            newPassword: newPassword
        }

        return axios.post(`changePassword.php`, JSON.stringify(data))
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{                
                //Format Unknown Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
            })
    },

	createRecord: function(layout: string, filename: string) {
		const postData = {
			fieldData : {
				filename: filename
			}
		}
		return axios.post(`proxy.php?/layouts/` + layout + `/records`, postData)
		.then((response) => {
			return response.data
		})
		.catch((e)=>{                
			//Format Unknown Error
			return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
		})
	},

    findBookings: function(query: object){
        return axios.post(`findBookings.php`, JSON.stringify(query))
        .then((response: any)=>{
            return response.data
        })
        .catch((e: any)=>{
            return `{ messages:[message: ${e.message}, code: -1], response:{}}`;
        })
    },

    findCargo: function(query: object){
        return axios.post(`findCargo.php`, JSON.stringify(query))
        .then((response: any)=>{
            return response.data
        })
        .catch((e: any)=>{
            return `{ messages:[message: ${e.message}, code: -1], response:{}}`;
        })
    },

    findRecord: function(layout: string, query: object){
        const findQuery = {
            query: query,
            limit: 1000
        }
        return axios.post(`proxy.php?/layouts/` + layout + `/_find`, findQuery)
        .then((response) => {
			return response.data
		})
		.catch((e)=>{                
			//Format Unknown Error
			return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
		})
    },

    findContact: function(query: object){
       
        return axios.post('findContact.php', query)
        .then((response) => {
			return response.data
		})
		.catch((e)=>{                
			//Format Unknown Error
			return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
		})
    },

    findCompany: function(query: object){
       
        return axios.post('findCompanyRegistration.php', query)
        .then((response) => {
			return response.data
		})
		.catch((e)=>{                
			//Format Unknown Error
			return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
		})
    },

    findLocation: function(query: object){
       
        return axios.post('findLocationRegistration.php', query)
        .then((response) => {
			return response.data
		})
		.catch((e)=>{                
			//Format Unknown Error
			return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
		})
    },

    getLayoutMetaData: function(layout: string, cb: any){
        getLayoutMetaData(layout, cb)
    },

    resendMfaCodeSignup: function(parameters: string) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : 'Contact - Send MFA Code',
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },

    verifyMfaCodeSignup: function(parameters: string) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : 'Contact - Verify MFA Code',
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },

    resetPassword: function(parameters: string) {
        const postData = {
            fieldData : {
                postData: parameters
            },
            script : 'Contact - Reset User Password',
            'script.param' : parameters
        }
        return axios.post(`runScript.php`, postData)
            .then((response)=>{
                return response.data
            })
            .catch((e)=>{
                if(e.response.data.messages[0].message){
                    //Return FM Error Response
                    return e.response.data
                }else{
                    //Format Unknown Error Like a FM Error
                    return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;
                }
            })
    },

    getPreferences: function(){
       
        return axios.post('getPreferences.php')
        .then((response) => {
			return response.data
		})
		.catch((e)=>{                
			//Format Unknown Error
			return  `{ messages:[message: ${e.message}, code: -1],response: {}}`;                
		})
    },

    displayDocument: function(query: object){
        return axios.post(`displayocument.php`, JSON.stringify(query))
        .then((response: any)=>{
            return response.data
        })
        .catch((e: any)=>{
            return `{ messages:[message: ${e.message}, code: -1], response:{}}`;
        })
    },

}

async function getLayoutMetaData(layout: string, cb:any) {
    const data = {
        layoutName: layout
    }
    axios.post('layoutMetaData.php', JSON.stringify(data))
    .then(response => {
        cb({
            data: response.data
        })
    }, error => {
        cb({
            error: error
        })
    })
}