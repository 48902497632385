<template>
	<div class="relative flex items-start">
		<div class="flex h-5 items-center">
			<input
				:value="modelValue"
				:id="id"
				:checked="modelValue"
				aria-describedby="checkbox-description"
				class="h-4 w-4 rounded border-gray-300 text-brand-700 focus:ring-brand-700"
				@input="onInput"
				:name="name"
				type="checkbox">
		</div>
		<div class="ml-3 text-sm">
			<label
				:for="id"
				class="font-medium text-gray-700">{{ label }}</label>
			<p
				v-if="description"
				id="checkbox-description"
				class="text-gray-600">
				{{ description }}
			</p>
		</div>
	</div>
</template>

<script setup lang="ts">

	import { 
		defineEmits, 
		defineProps, 
		nextTick,
		onMounted
	} from 'vue'

	const props = defineProps({ 
		modelValue: {
			type: Boolean,
			default: null
		},
		description: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: 'Checkbox'
		},
		label: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: ''
		}
	})

	onMounted(() => {
		// check checkbox if initial value is true
		if(props.modelValue){
			const checkbox = document.getElementById(
				props.id,
			) as HTMLInputElement;
			checkbox.checked = true;
		}
	})

	const emit = defineEmits([
		'update:modelValue'
	])

	const onInput = async() => {
		const checkbox = document.getElementById(
			props.id,
		) as HTMLInputElement;

		emit('update:modelValue', checkbox?.checked);
		await nextTick()
		return
	}
</script>
