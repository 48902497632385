<template>
	<svg
		version="1.2"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink" 
		x="0px"
		y="0px"
		:width="size"
		:height="size"
		viewBox="0 0 63.84 36.41"> 
		<path
			:fill="color"
			class="fm_fill"
			d="M29.194981,27.270031C28.307983,27.270032 27.589994,27.989018 27.589994,28.874018 27.589994,29.760972 28.307983,30.48002 29.194981,30.48002 30.081003,30.48002 30.798994,29.760972 30.798994,28.874018 30.798994,27.989018 30.081003,27.270032 29.194981,27.270031z M34.969978,27.27002C34.083973,27.27002 33.365967,27.989012 33.365967,28.874002 33.365967,29.760993 34.083973,30.479986 34.969978,30.479985 35.856979,30.479986 36.574986,29.760993 36.574986,28.874002 36.574986,27.989012 35.856979,27.27002 34.969978,27.27002z M40.744484,27.270019C39.858761,27.27002 39.139984,27.989012 39.139984,28.874002 39.139984,29.760993 39.858761,30.479986 40.744484,30.479985 41.630211,30.479986 42.348988,29.760993 42.348988,28.874002 42.348988,27.989012 41.630211,27.27002 40.744484,27.270019z M0,23.580999L63.844803,23.580999C63.844803,23.580999,65.127823,34.170043,60.315819,36.414L9.3029547,36.414C9.3029547,36.414,2.246973,27.431033,0,23.580999z M25.987,16.041999L32.083,16.041999 32.083,20.051999 25.987,20.051999z M17.164,16.041999L23.26,16.041999 23.26,20.051999 17.164,20.051999z M8.5019999,16.041999L14.597,16.041999 14.597,20.051999 8.5019999,20.051999z M44.915001,13.153999L54.540001,13.153999 58.870998,13.153999 58.870998,15.559999 58.870998,18.127999 58.872002,18.127999 58.872002,19.891999 58.870998,19.891999 54.540001,19.891999 44.433998,19.891999 44.433998,18.127999 54.540001,18.127999 54.540001,15.559999 44.915001,15.559999z M21.497,10.209L27.593,10.209 27.593,14.218999 21.497,14.218999z M13.164,10.209L19.26,10.209 19.26,14.218999 13.164,14.218999z M17.052999,4.2089996L23.149,4.2089996 23.149,8.2189989 17.052999,8.2189989z M52.935001,0L54.701,0 54.701,6.8969994 54.700001,6.8969994 54.700001,10.426999 50.849998,10.426999 50.849998,6.5769997 52.935001,6.5769997z" /> 
	</svg>
</template>

<script lang="ts">
	// name the component
	export default { name: 'ShipIcon' }
</script>

<script setup lang="ts">

    const props = defineProps({ 
		size: {
            type: String
        },
        color: {
            type: String
        }
	})


</script>