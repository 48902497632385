import AccountDetails from './AccountDetails.vue'

const config = {
	mainRoute: {
		name: "AccountDetails",
		path: "/account-details",
		component: AccountDetails
	},
	module: "setup"
}

export { config }