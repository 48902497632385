<template>
	<div class="flex min-h-screen flex-1">
		<!-- Background image -->
		<div class="hidden w-0 flex-1 lg:block min-h-screen relative overflow-hidden">
			<img
				class="absolute inset-0 h-full min-h-260 w-full object-cover"
				src="@/assets/ucbg.jpeg"
				alt="">
		</div>

		<!-- Form -->
		<div class="min-h-screen min-w-full lg:min-w-0">
			<div class="min-h-screen">
				<div class="py-3 px-6 flex flex-1 flex-col justify-start lg:flex-none bg-white min-h-[90.6vh]">
					<div class="w-full px-4 sm:px-6 h-full">
						<div>
							<img
								class="h-52 w-auto mx-auto"
								src="@/assets/logo-vertical.png"
								alt="Global Marine Transportation Logo">
							<h2 class="text-center mt-8 text-lg lg:text-2xl font-bold leading-9 tracking-tight text-gray-900">
								Reset Password
							</h2>
						</div>
  
						<div class="mt-10 max-w-sm lg:w-96 mx-auto">
							<div>
								<form
									@submit.prevent="resetPassword"
									class="space-y-6">
									<div>
										<tw-input
											label="Email"
											placeholder="Email"
											type="email"
											required
											v-model="email" />
									</div>

									<!-- Hidden text -->
									<div 
										v-show="hasError" 
										class="text-red-500 mt-3">
										{{ errorMessage }}
									</div>

									<!-- Loading icon -->							
									<div 
										v-if="loading"
										class="flex justify-center items-center">
										<tw-loading />
										<p class="text-orange-500 ml-3">Resetting password...</p>
									</div>	
  
									<div v-if="!loading">
										<button
											type="submit"
											class="btn-green flex w-full justify-center py-2">
											Reset
										</button>
									</div>

									<div class="flex items-center justify-between">
										<div class="text-sm leading-6 mx-auto">
											<router-link 
												:to="{name: 'LoginMain'}" 
												class="link">
												Back To Login
											</router-link>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				
				<tw-footer />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'ResetPassword' }
</script>

<script setup lang="ts">

	// API
	import sharedApi from '@/api/SharedApi'

	// Router
    import {
		useRouter,
		useRoute
	} from 'vue-router'

	// Vue
    import { 
		ref
	} from 'vue'
	

    const router = useRouter()
    const route = useRoute()


    const email = ref('')
	const hasError = ref(false)
	const errorMessage = ref('')
	const loading = ref(false)

	async function resetPassword(){
		loading.value = true

		let params = {
				email: email.value
            }

		try{
			let result = await sharedApi.resetPassword(JSON.stringify(params))

			let scriptResult = JSON.parse(result.response.scriptResult)

			if(scriptResult.errorCode != 0){
				let error = scriptResult.errorMessage
				throw error
			}
			else {
				// Navigate back to login page
				router.push(route?.query?.redirect ? String(route?.query?.redirect) : { name: 'LoginMain' })
			}
		} 
		catch(error: any){
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
			loading.value = false
		}
	}

</script>