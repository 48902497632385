<template>
	<div class="flex min-h-screen flex-1">
		<!-- Background image -->
		<div class="hidden w-0 flex-1 lg:block min-h-screen relative overflow-hidden">
			<img
				class="absolute inset-0 h-full min-h-260 w-full object-cover"
				src="@/assets/ucbg.jpeg"
				alt="">
		</div>

		<!-- Logout Form -->
		<div class="min-h-screen min-w-full lg:min-w-0">
			<div class="min-h-screen">
				<div class="py-3 px-6 flex flex-1 flex-col justify-start lg:flex-none bg-white min-h-[90.6vh]">
					<div class="w-full px-4 sm:px-6 h-full">
						<div>
							<img
								class="h-52 w-auto mx-auto"
								src="@/assets/logo-vertical.png"
								alt="Global Marine Transportation Logo">
							<h2 class="text-center mt-8 text-lg lg:text-2xl font-bold leading-9 tracking-tight text-gray-900">
								Customer Logout
							</h2>
						</div>
						
						<div class="mt-10 max-w-sm lg:w-96 mx-auto border border-orange-400">
							<p class="text-lg text-center leading-9 p-6 font-bold tracking-tight text-gray-900">
								Thank you for visiting the <br> Global Marine Transportation Inc. <br> web portal
							</p>
						</div>
			
						<div class="mt-10 flex items-center justify-between">
							<div class="text-sm leading-6 mx-auto">
								<router-link 
									:to="{name: 'LoginMain'}" 
									class="link">
									Back To Login
								</router-link>
							</div>
						</div>
					</div>
				</div>
				
				<tw-footer />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'LogoutForm' }
</script>

<script setup lang="ts">

	// API
	import LoginAPI from '../api/LoginApi'

	// Vue
    import { 
		onMounted
	} from 'vue'


	onMounted(() => {
		setTimeout(() => {
			LoginAPI.logout()
		}, 20000)
	})


</script>