
import Login from './LoginMain.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import Logout from './components/LogoutForm.vue'

const config = {
	mainRoute: {
		path: "/",
		name: "LoginMain",
		props: true,
		component: Login,
		layout: AuthLayout
	},
	mfaAuth: {
		path: '/mfa-auth',
		name: 'MfaForm',
		props: true,
		component: Login,
		layout: AuthLayout
	},
	mfaSignup: {
		path: '/mfa-signup/:id',
		name: 'MfaFormSignup',
		props: true,
		component: Login,
		layout: AuthLayout
	},
	resetPassword: {
		path: '/reset-password',
		name: 'ResetPassword',
		component: Login,
		layout: AuthLayout
	},
	updatePassword: {
		path: '/update-password',
		name: 'UpdatePassword',
		component: Login,
		layout: AuthLayout
	},
	signUp: {
		path: "/signup",
		name: "SignUp",
		props: true,
		component: Login,
		layout: AuthLayout
	},
	signupVerification: {
		path: "/signup-verification",
		name: "SignupVerification",
		props: true,
		component: Login,
		layout: AuthLayout
	},
	loginFormSignup: {
		path: "/signup-login",
		name: "LoginFormSignup",
		props: true,
		component: Login,
		layout: AuthLayout
	},
	logout: {
		path: "/logout",
		name: "Logout",
		component: Logout,
		layout: AuthLayout
	}
}

export { config }