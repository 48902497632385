<template>
	<TransitionRoot
		as="template">
		<Dialog
			as="div"
			class="fixed z-50 inset-0 overflow-y-auto">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span
					class="hidden sm:inline-block sm:align-middle sm:h-screen"
					aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div class="relative inline-block align-bottom bg-white rounded-none px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
						<div class="text-center sm:mt-0 sm:text-left mb-10">
							<DialogTitle
								as="h1"
								class="text-2xl leading-6 font-bold text-red-500 uppercase">
								Remove Shipper Hold
							</DialogTitle>
							<DialogDescription
								as="h3"
								class="mb-3 leading-8">
								Do you wish to remove the shipper hold for:
								<br />
								Booking #: {{ props.booking['_Booking#'] }}
								<br />
								GMT #: {{ props.booking['_GMT#'] }}
							</DialogDescription>
						</div>

						<div class="mt-5 sm:mt-10 sm:flex sm:flex-row justify-end space-x-4">
							<tw-button
								class="btn-red uppercase"
								@click="emit('closeModal', false)">
								Cancel
							</tw-button>
							<tw-button
								@click="removeShipperHold"
								class="btn-green uppercase">
								Continue
							</tw-button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
	// name the component
	export default { name: "ConfirmationModal" }
</script>

<script setup lang="ts">

	import { 
		ExclamationCircleIcon
	} from '@heroicons/vue/20/solid'

	// API
	import sharedApi from '@/api/SharedApi'

	// Components
	import { 
		Dialog, 
		DialogTitle, 
		DialogDescription, 
		DialogOverlay, 
		Menu, 
		MenuButton, 
		MenuItem, 
		MenuItems,
		TransitionRoot, 
		TransitionChild
	} from '@headlessui/vue'

	// Store
	import { userStore } from '@/store/index'

	// Vue
	import {
		ref
	} from 'vue'

	const showAll = ref(false)

	const emit = defineEmits(['closeModal', 'scriptSuccess'])

	const store = userStore()

	//eslint-disable-next-line
	const props = defineProps({ 
		booking: {
            type: Object
        },
		loadDataFunction: {
			type: Function,
			default: () => {}
		}
	})

	async function removeShipperHold(){
		let params = {
				bookingID: props.booking['_GMT#'],
				email: store.user.email
            }
		
		try {
		
            let result = await sharedApi.runScript("Booking - Remove Shipper Hold", JSON.stringify(params))

			if (JSON.parse(result.response.scriptResult).errorCode != 0){
                let error = JSON.parse(result.response.scriptResult).errorMessage
				throw error
            }

			props.loadDataFunction()

			emit('scriptSuccess', true)
			emit('closeModal', false)

        } catch (error: any) {
            console.log('Error:', error)
        }
	}

</script>