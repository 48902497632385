import { router } from '@/router';
import axios from 'axios'
import { userStore } from '@/store/index'

export default {
    

    login: function(username: string, password: string, cb: any){
        authenticate(username, password, cb)
    },
    logout: function(){
        signOut();
    }
    
}

async function authenticate(email: string, password: string, cb: any) {
    const data = {
        email: email,
        password: password,
    }
    
    axios.post('login.php', JSON.stringify(data))
    .then(response => {
        if (response.data.loggedIn == true){
            cb({
                authenticated: true,
                user: response.data                
            })
        }else{
            cb({
                authenticated: false,
                errorMsg: response.data.message
            })
        }    
    }, error => {
        console.log(error)
        cb({
            authenticated: false,
            errorMsg: error,
            error: error
        })
    })
}


async function signOut(){
	
    axios.post('logout.php')
    .then(response => {
        const store = userStore()
        // store.setUserData({})
        const user = {
            kptID: "",
            kftCompanyID: "",
            email: "",
            firstName: "",
            lastName: "",
            companyName: "",
            locationName: "",
            sessionId: "",
            isPasswordChangeNeeded: "",
            loggedIn:false
        }

        // update store
		Object.assign(store.user, user)

        router.push({name: 'LoginMain'})
		delete localStorage.sessionId;

    }, error => {
        console.log(error)
    })
}
