import { Nav } from '@/types' // must be imported since file contains an export statement
import { config } from "./config"

export const nav: Nav = { 
    name: config.nav.name, 
    to: {name: config.nav.to},
    icon: config.nav.icon, 
    position: config.nav.position,
    show: config.nav.show,
	module: config.module
}