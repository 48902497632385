<template>
	<slot ref="column" />
</template>

<script lang="ts">
	// name the component
	export default { name: 'TwTableHeader' }
</script>

<script setup lang="ts">
	import { 
		defineProps
	} from 'vue'

	// eslint-disable-next-line
	const props = defineProps({
		defaultSort: {
			type: Object,
			default: () => {return {prop: null, order: 'ASC'}}
		}
	})
</script>