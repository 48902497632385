import { createRouter, createWebHistory } from 'vue-router'
import { Route } from '@/types'; // must be imported since file contains an export statement
import { userStore } from '@/store/index'




// Import all of the resource routes files
function loadRoutes() {
    const context = require.context('@/modules', true, /routes.ts$/i)
    return context.keys()
        .map(context) // import module
        .map((m: any) => m.route) // get `default` export from each resolved module
}

// Import all of the component routes files
function loadComponentRoutes() {
    const context = require.context('@/components', true, /routes.ts$/i)
    return context.keys()
        .map(context) // import module
        .map((m: any) => m.route) // get `default` export from each resolved module
}

// Define the routes
const routes: Route[] = []
const resourceRoutes = loadRoutes();

resourceRoutes.forEach((moduleRoutes) => {
    moduleRoutes.forEach((route: Route) => {
        routes.push(route)
    })
});

/*--- Routes for example components ---*/
if(process.env.VUE_APP_ENV === 'local'){
    const componenteRoutes = loadComponentRoutes();

    componenteRoutes.forEach((moduleRoutes) => {
        moduleRoutes.forEach((route: Route) => {
            routes.push(route)
        })
    });
}

// Create the router instance and pass the `routes` option
export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), // use history mode
    //history: createWebHistory('/gmt/fm-web/dist/'), // use history mode
    routes // short for `routes: routes`
})

// Redirect user if they are not authorized to view pages
router.beforeEach(async (to, from, next) => {
    const store = userStore()
    if(to.name !== 'LoginMain' && 
	to.name !== 'SignUp' && 
	to.name !== 'UpdatePassword' &&
	to.name !== 'ResetPassword' && 
	to.name !== 'MfaForm' && 
    to.name !== 'MfaFormSignup' && 
    to.name !== 'LoginFormSignup' && 
    to.name !== 'SignupVerification' && 
	!store?.user.loggedIn){
        next({name: 'LoginMain', query: {redirect: to.fullPath}})
    }
    else next()
})
