<template>
	<div class="flex min-h-screen flex-1">
		<!-- Background image -->
		<div class="hidden w-0 flex-1 lg:block min-h-screen relative overflow-hidden">
			<img
				class="absolute inset-0 h-full min-h-260 w-full object-cover"
				src="@/assets/ucbg.jpeg"
				alt="">
		</div>

		<!-- Form -->
		<div class="min-h-screen min-w-full lg:min-w-0">
			<div class="min-h-screen">
				<div class="py-3 px-6 flex flex-1 flex-col justify-start lg:flex-none bg-white min-h-[90.6vh]">
					<div class="w-full px-4 sm:px-6 h-full">
						<div>
							<img
								class="h-52 w-auto mx-auto"
								src="@/assets/logo-vertical.png"
								alt="Global Marine Transportation Logo">
							<h2 class="text-center mt-8 text-lg lg:text-2xl font-bold leading-9 tracking-tight text-gray-900">
								Web Access Setup
							</h2>
						</div>
  
						<div class="mt-10 max-w-sm lg:w-96 mx-auto">
							<div>
								<form
									@submit.prevent="verifyMfaCode"
									class="space-y-6">

									<div class="text-sm text-red-500">
										Please check your email for the multi-factor authentication code.
									</div>

									<div>
										<tw-input
											label="MFA Code"
											placeholder="Multi-Factor Authentication Code"
											required
											v-model="mfaCode" />
									</div>

									<div class="text-sm">
										Please enter the multi-factor authentication code above.
									</div>

									<!-- Hidden text -->
									<div 
										v-show="hasError" 
										class="text-red-500 mt-3">
										{{ errorMessage }}
									</div>

									<!-- Loading icon -->							
									<div 
										v-if="loading"
										class="flex justify-center items-center">
										<tw-loading />
										<p class="text-orange-500 ml-3">Verifying MFA code...</p>
									</div>	
  
									<div v-if="!loading">
										<button
											type="submit"
											class="btn-green flex w-full justify-center">
											Continue
										</button>
									</div>

									<div class="flex items-center justify-between">
										<div class="text-sm leading-6">
											<router-link 
												:to="{name: 'LoginMain'}" 
												class="link">
												Back To Login
											</router-link>
										</div>
										<div class="flex items-center">
											<a
												href="#"
												class="link text-sm"
												@click="resendMfaCode">Re-Send MFA Code</a>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<tw-footer />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: 'MfaFormSignup' }
</script>

<script setup lang="ts">

	// API
	import sharedApi from '@/api/SharedApi'

	// Router
	import {
		useRouter,
		useRoute
	} from 'vue-router'

	// Store
	import { userStore } from '@/store/index'

	// Vue
    import {
        onMounted,
        ref
    } from 'vue'


	const store = userStore()
	const router = useRouter()
	const route = useRoute()
    const hasError = ref(false)
	const errorMessage = ref('')
	const mfaCode = ref('')
	const user = ref({})

	onMounted(() => {
		loadPage()
	})

	async function loadPage(){

		let params = {
			contactID: route.params.id
		}

		try{
			let result = await sharedApi.findContact(params)

			if(result.messages[0].code != 0){
				let error = result.messages[0].message
				throw error
			}
			else{
				let data = result.response.data[0].fieldData

				user.value = data

				store.user.firstName = data.firstName
				store.user.lastName = data.lastName
				store.user.email = data.Email1
				store.user.companyName = data.companyName
				store.user.locationName = data.locationName
				store.user.kptID = route.params.id 
			}
		} 
		catch(error: any){
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
		}
	}

	const loading = ref(false)

	async function verifyMfaCode(){
		loading.value = true
		
		let params = {
				contactID: route.params.id,
				mfaCode: mfaCode.value
            }

		try{

			let result = await sharedApi.verifyMfaCodeSignup(JSON.stringify(params))

			let scriptResult = JSON.parse(result.response.scriptResult)

			if(scriptResult.errorCode != 0){
				let error = scriptResult.errorMessage
				throw error
			}
			else{
				router.push({name:'SignUp'})
			}
		} 
		catch(error: any){
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
			loading.value = false
		}
	}

	async function resendMfaCode(){
		
		let params = {
				contactID: route.params.id
            }

		try{
			let result = await sharedApi.resendMfaCodeSignup(JSON.stringify(params))

			let scriptResult = JSON.parse(result.response.scriptResult)

			if(scriptResult.errorCode != 0){
				let error = scriptResult.errorMessage
				throw error
			}
		} 
		catch(error: any){
			console.log('Error', error)
			hasError.value = true
			errorMessage.value = error
		}
	}

</script>