<template>
	<svg
		version="1.2"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink" 
		x="0px"
		y="0px"
		:width="size"
		:height="size"
		viewBox="0 0 35.61 62.92"> 
		<path
			:fill="color"
			class="fm_fill"
			d="M12.343644,53.077L12.343644,58.7033 18.960813,58.7033 18.960813,53.077z M2.5428686,53.077L2.5428686,58.7033 9.1600178,58.7033 9.1600178,53.077z M12.343644,41.038002L12.343644,46.662899 18.960813,46.662899 18.960813,41.038002z M2.5428686,41.038002L2.5428686,46.662899 9.1600178,46.662899 9.1600178,41.038002z M24.031356,40.340874L24.031356,45.966057 30.648467,45.966057 30.648467,40.340874z M12.343644,28.9988L12.343644,34.623798 18.960813,34.623798 18.960813,28.9988z M2.5428686,28.9988L2.5428686,34.623798 9.1600178,34.623798 9.1600178,28.9988z M24.031356,28.30191L24.031356,33.926994 30.648467,33.926994 30.648467,28.30191z M0,24.779999L21.496001,24.779999 21.496001,62.922001 0,62.922001z M24.031356,16.261648L24.031356,21.887932 30.648467,21.887932 30.648467,16.261648z M12.343914,16.261648L12.343914,21.887932 18.961024,21.887932 18.961024,16.261648z M24.031356,4.4921155L24.031356,10.117167 30.648467,10.117167 30.648467,4.4921155z M12.343914,4.4921155L12.343914,10.117167 18.961024,10.117167 18.961024,4.4921155z M7.3830005,0L35.608001,0 35.608001,62.893002 22.574276,62.893002 22.574276,23.701626 7.3830005,23.701626z" /> 
	</svg>
</template>

<script lang="ts">
	// name the component
	export default { name: 'CityIcon' }
</script>

<script setup lang="ts">

    const props = defineProps({ 
		size: {
            type: String
        },
        color: {
            type: String
        }
	})


</script>