<template>
	<div>
		<main>
			<div class="">
				<!-- Replace with your content -->
				<div class="min-h-screen">
					<div class="">
						<slot />
					</div>
				</div>
				<!-- /End replace -->
			</div>
		</main>
	</div>
</template>

<script>

  export default { 
    name: 'AuthLayout',
    components: {
    },
    setup() {
    },
  }
  
</script>