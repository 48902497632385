<template>
	<slot />
</template>

<script lang="ts">
	// name the component
	export default { name: 'TwTableRow' }
</script>

<script setup lang="ts">
	import { 
		defineProps
	} from 'vue'

	// eslint-disable-next-line
	const props = defineProps({
		row: {
			type: Object,
			default: () => {return {}}
		}
	})
</script>