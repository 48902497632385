import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"

export const route: Route[] = [
	// main route
    {
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			layout: config.mainRoute.layout
		}
    },
	{
		path: config.mfaAuth.path,
		name: config.mfaAuth.name,
		component: config.mfaAuth.component,
		meta: {
			layout: config.mfaAuth.layout
		}
    },
	{
		path: config.mfaSignup.path,
		name: config.mfaSignup.name,
		component: config.mfaSignup.component,
		meta: {
			layout: config.mfaSignup.layout
		}
    },
	{
		path: config.resetPassword.path,
		name: config.resetPassword.name,
		component: config.resetPassword.component,
		meta: {
			layout: config.resetPassword.layout
		}
    },
	{
		path: config.updatePassword.path,
		name: config.updatePassword.name,
		component: config.updatePassword.component,
		meta: {
			layout: config.updatePassword.layout
		}
    },
	{
		path: config.signUp.path,
		name: config.signUp.name,
		component: config.signUp.component,
		meta: {
			layout: config.signUp.layout
		}
    },
	{
		path: config.signupVerification.path,
		name: config.signupVerification.name,
		component: config.signupVerification.component,
		meta: {
			layout: config.signupVerification.layout
		}
    },
	{
		path: config.loginFormSignup.path,
		name: config.loginFormSignup.name,
		component: config.loginFormSignup.component,
		meta: {
			layout: config.loginFormSignup.layout
		}
    },
	{
		path: config.logout.path,
		name: config.logout.name,
		component: config.logout.component,
		meta: {
			layout: config.logout.layout
		}
    }
	// other routes below
];