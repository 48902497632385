<template>
	<div class="h-screen flex overflow-hidden bg-gray-100">
		<TransitionRoot
			as="template"
			:show="sidebarOpen">
			<Dialog
				as="div"
				static
				class="fixed inset-0 flex z-40 md:hidden"
				@close="sidebarOpen = false"
				:open="sidebarOpen">
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</TransitionChild>
				<TransitionChild
					as="template"
					enter="transition ease-in-out duration-300 transform"
					enter-from="-translate-x-full"
					enter-to="translate-x-0"
					leave="transition ease-in-out duration-300 transform"
					leave-from="translate-x-0"
					leave-to="-translate-x-full">
					<div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 brand-color">
						<TransitionChild
							as="template"
							enter="ease-in-out duration-300"
							enter-from="opacity-0"
							enter-to="opacity-100"
							leave="ease-in-out duration-300"
							leave-from="opacity-100"
							leave-to="opacity-0">
							<div class="absolute top-0 right-0 -mr-12 pt-2">
								<button
									type="button"
									class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
									@click="sidebarOpen = false">
									<span class="sr-only">Close sidebar</span>
									<XMarkIcon
										class="h-6 w-6 text-white"
										aria-hidden="true" />
								</button>
							</div>
						</TransitionChild>
						<div class="flex-shrink-0 flex items-center px-4">

						</div>
						<div class="mt-5 flex-1 h-0 overflow-y-auto">
							<nav class="space-y-1">
								<router-link
									v-for="item in navigation"
									:key="item.name"
									:to="item.to"
									:class="[$route.meta.module == item.module ? 'bg-blue-400 text-white' : 'text-white hover:bg-blue-600 hover:text-white', 'group flex items-center px-4 py-4 text-base font-medium']">
									<component
										:is="item.icon"
										class="mr-3 flex-shrink-0 h-6 w-6 text-white group-hover:text-white"
										aria-hidden="true" />
									{{ item.name }}
								</router-link>
							</nav>
						</div>
					</div>
				</TransitionChild>
				<div
					class="flex-shrink-0 w-14"
					aria-hidden="true">
					<!-- Dummy element to force sidebar to shrink to fit close icon -->
				</div>
			</Dialog>
		</TransitionRoot>

		<!-- Static sidebar for desktop -->
		<div class="hidden brand-color md:flex md:flex-shrink-0">
			<div class="flex flex-col w-64">
				<!-- Sidebar component, swap this element with another sidebar if you like -->
				<div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto border-r border-gray-400">
					<div class="flex items-center justify-center flex-shrink-0 px-4">
		
					</div>
					<div class="mt-11 flex-1 flex flex-col">
						<nav class="flex-1 space-y-1">
							<router-link
								v-for="item in navigation"
								:key="item.name"
								:to="item.to"
								:class="[$route.meta.module == item.module ? 'bg-blue-400 text-white' : 'text-white hover:bg-blue-600 hover:text-white', 'group flex items-center px-4 py-4 text-sm font-medium']">
								<component
									:is="item.icon"
									class="mr-3 flex-shrink-0 h-6 w-6 text-white group-hover:text-white"
									aria-hidden="true" />
								{{ item.name }}
							</router-link>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-col w-0 flex-1 overflow-hidden">
			<div class="relative z-10 flex-shrink-0 flex h-16 bg-white  ring-2 ring-black">
				<button
					type="button"
					class="px-4 border-r border-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
					@click="sidebarOpen = true">
					<span class="sr-only">Open sidebar</span>
					<Bars3BottomLeftIcon
						class="h-6 w-6"
						aria-hidden="true" />
				</button>
				<div class="flex-1 px-4 flex">
					<div class="flex-1 flex items-center">
						<div>
							<img
								@click="() => router.push({name: 'Dashboard'})"
								class="h-15 w-auto mx-auto cursor-pointer"
								src="@/assets/logo-horizontal.png"
								alt="Global Marine Transportation Logo" />
						</div>
						<div class="max-w-7xl mx-4 ">
							<h1
								v-if="$route.meta.headerName"
								class="text-2xl font-semibold text-gray-900">
								{{ $route.meta.headerName }}
							</h1>
						</div>
					</div>
					<div class="ml-4 flex items-center md:ml-6">            
						<Menu
							as="div"
							class="ml-3 relative">
							<div>
								<MenuButton class="max-w-xs bg-white items-center  flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
									<span>Welcome, {{ store.user.firstName }}</span>               
									<ChevronDownIcon class="inline h-4 pl-4" />
								</MenuButton>
							</div>
							<transition
								enter-active-class="transition ease-out duration-100"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95">
								<MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
									<MenuItem
										v-for="item in userNavigation"
										:key="item.name"
										v-slot="{ active }">
										<div
											:class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
											@click="item.function">
											{{ item.name }}
										</div>
									</MenuItem>
								</MenuItems>
							</transition>
						</Menu>
					</div>
				</div>
			</div>

			<main class="bg-gray-50 flex-1 relative overflow-y-auto focus:outline-none">
				<div class="py-6">
					<div class="mx-auto px-4 sm:px-6 md:px-8">
						<!-- Replace with your content -->
						<div class="py-4">
							<slot />
						</div>
						<!-- /End replace -->
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: "DefaultLayout" }
</script>

<script setup lang="ts">
import { defineComponent, ref } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  Bars3BottomLeftIcon,
  XMarkIcon,
  ChevronDownIcon
} from '@heroicons/vue/24/outline'
import { userStore } from '@/store/index'

import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
import LoginAPI from '../modules/login/api/LoginApi'

// Router
import {
	useRouter
} from 'vue-router'

const router = useRouter()
const store = userStore()

const userNavigation = [
    { name: 'Log Out', function: logout },
	// { name: 'My Profile', function: () => router.push({name:'AccountDetails'})}
]

    const sidebarOpen = ref(false)

    //Load file path of all modules named Nav.vue
    const context = require.context('@/modules', true, /Nav.ts$/i)

    //Import each component
    const modules = context.keys()
        .map(context)
        .map(m => m.nav) 

    //Sort them based on the position data prop
    const navigation = modules.filter(m =>{return m.show}).sort((a,b)=>{
        let aPos = a.position
        let bPos = b.position
        if(aPos < bPos){
            return -1
        }else if(aPos > bPos){
            return 1
        }else{
            return 0
        }
    })

	function logout(){
		router.push({name: 'Logout'})
	}



</script>